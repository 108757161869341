import request from './request'

// 查询卡片持有人信息列表
export function listCardHolder(query) {
  return request({
    url: '/card/cardHolder/list',
    method: 'get',
    params: query
  })
}

// 查询卡片持有人信息详细
export function getCardHolder(id) {
  return request({
    url: '/card/cardHolder/' + id,
    method: 'get'
  })
}

// 新增卡片持有人信息
export function addCardHolder(data) {
  return request({
    url: '/card/cardHolder',
    method: 'post',
    data: data
  })
}

// 修改卡片持有人信息
export function updateCardHolder(data) {
  return request({
    url: '/card/cardHolder',
    method: 'put',
    data: data
  })
}

// 删除卡片持有人信息
export function delCardHolder(id) {
  return request({
    url: '/card/cardHolder/' + id,
    method: 'delete'
  })
}

// 导出卡片持有人信息
export function exportCardHolder(query) {
  return request({
    url: '/card/cardHolder/export',
    method: 'get',
    params: query
  })
}