<template>
  <div class="page-header align-items-start min-vh-100" style="
      background-image: url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80');
    ">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="left">
          <div class="left-wrap">
            <ul style="padding-left: 0;align-self: center;">
              <li style="display: flex;margin-bottom: 40px;">
                <img src="../assets/img/login/bank.svg">
                <div style="margin: 15px 0px 0px 24px;">
                  <h5 class="title">全球收款帐户</h5>
                  <p class="text">线上创建企业全球收款帐户，即刻开启多币种、多平台全球收款。</p>
                </div>
              </li>
              <li style="display: flex;margin-bottom: 40px;">
                <img src="../assets/img/login/jet.svg">
                <div style="margin: 15px 0px 0px 24px;">
                  <h5 class="title">付款与换汇</h5>
                  <p class="text">全球支付网络与强大外汇引擎，实现快速、低成本、安全的国际付款。</p>
                </div>
              </li>
              <li style="display: flex;margin-bottom: 40px;">
                <img src="../assets/img/login/card.svg">
                <div style="margin: 15px 0px 0px 24px;">
                  <h5 class="title">ITT CARD虚拟信用卡</h5>
                  <p class="text">线上高效开卡，一卡打通多场景全球支付。</p>
                </div>
              </li>
              <li style="display: flex;margin-bottom: 20px;">
                <img src="../assets/img/login/Receive.svg">
                <div style="margin: 15px 0px 0px 24px;">
                  <h5 class="title">全面守护</h5>
                  <p class="text">支持双因素验证，可绑定Google验证器，Truly等金融级2FA验证器，账户全面守护</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-8 col-12 mx-auto d-flex align-items-center">
          <div class="card z-index-0 fadeIn3 fadeInBottom" >
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div class="shadow-primary border-radius-lg py-3 pe-1"
                style="background-color: #434345;box-shadow:0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(67, 67, 69, 0.4) !important;">
                <h4 class="text-white font-weight-bolder text-center mb-0">
                  <img src="../assets/logo/logo.png" class="w-10" style="margin-right: 10px;margin-top: -7px;">
                  <!-- <img src="../assets/img/login/logo-w.png" class="w-40"> -->
                  ITT CARD
                </h4>
              </div>
            </div>
            <div class="card-body" v-if="!registerShow">
              <el-form class="text-start mt-3" :model="loginForm" :rules="loginRules" ref="loginRef">
                <div class="mb-3">
                  <el-form-item prop="username">
                    <material-input id="validationCustomUsername" type="email" label="账号/邮箱" name="email"
                      v-model="loginForm.username" />
                  </el-form-item>
                </div>
                <div class="mb-3">
                  <el-form-item prop="password">
                    <material-input id="password" type="password" label="密码" name="password"
                      v-model="loginForm.password" />
                  </el-form-item>
                </div>
                <div class="mb-3" style="display: flex;">
                  <el-form-item prop="code" style="width: 63%;">
                    <material-input id="code" type="string" label="验证码" name="code" v-model="loginForm.code" />
                  </el-form-item>
                  <div class="login-code" style="width: 33%;margin-left: 4%;">
                    <img :src="codeUrl" style="width: 100%;height: 40.19px;" @click="getCode" class="login-code-img" />
                  </div>
                </div>
                <material-switch id="rememberMe" name="rememberMe" :checked="true">记住密码</material-switch>
              </el-form>
              <div class="text-center" @click="handleLogin" >
                <el-button class="mb-3 btn-primary w-100 py-3 text-white" :loading="loading">登 录</el-button>
              </div>
              <div class="d-flex justify-content-between">
                <span class="mt-2 mb-2 text-sm" style="cursor: pointer;" @click="registerShow = true">忘记密码</span>
                <p class="mt-2 mb-2 text-sm">
                  还没有账号?
                  <router-link :to="{ name: 'SignUp' }"
                    class="text-primary text-gradient font-weight-bold">注册</router-link>
                </p>
              </div>
            </div>
            <div class="card-body" v-else>
              <el-form class="text-start mt-3" :model="registerForm" :rules="registerRules" ref="registerRef">
                <div class="mb-3">
                  <el-form-item prop="email">
                    <material-input id="validationCustomUsername" type="email" label="邮箱" name="email"
                      v-model="registerForm.email" />
                  </el-form-item>
                </div>
                <div class="mb-3 d-flex">
                  <!-- <el-form-item prop="emailCode" style="width: 63%;" > -->
                  <material-input id="code" link label="邮箱验证码" name="code" size="lg" v-model="registerForm.emailCode"
                    style="width: 63%;" />
                  <!-- </el-form-item> -->
                  <material-button color="primary" style="width: 33%;margin-left: 4%;min-width: 120px;"
                    @click="getEmailCode">{{ codeTime ? codeTime + 's' : '获取验证码' }}</material-button>
                </div>
                <div class="mb-3">
                  <el-form-item prop="password">
                    <material-input id="password" type="password" label="新密码" name="password"
                      v-model="registerForm.password" />
                  </el-form-item>
                </div>
                <div class="mb-3">
                  <el-form-item prop="confirmPassword">
                    <material-input id="password" type="password" label="确认密码" name="password"
                      v-model="registerForm.confirmPassword" />
                  </el-form-item>
                </div>
              </el-form>
              <div class="text-center" @click="handleRegister">
                <material-button class="mb-2" variant="gradient" color="primary" fullWidth>重 置 密 码</material-button>
              </div>
              <div class="d-flex justify-content-between">
                <div></div>
                <span class="mt-2 mb-2 text-sm" style="cursor: pointer;" @click="registerShow = false">使用已有账户登录</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-center">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white">
              Copyright © 2002-{{ new Date().getFullYear() }}
              <!-- <i class="fa fa-heart" aria-hidden="true"></i> -->
              By
              <a href="https://www.ITTCARD.com/" class="font-weight-bold text-white" target="_blank">ITT CARD团队</a>
              -专业的跨境支付一站式解决方案
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!--  双因素身份验证  -->
    <el-dialog title="双因素身份验证(2FA)" v-model="twoFactorOpen" width="420px" class="login_two_factor_form" center
      :close-on-click-modal="false">
      <el-form class="my-dialog-form" ref="twoFactorFormaRef" :model="twoFactorForm" :rules="twoFactorRules"
        label-width="auto">
        <el-form-item label="身份验证用户" class="u-m-b-10">
          <div class="font-bold u-font-17 text-theme">{{ loginForm.username }}</div>
        </el-form-item>
        <el-form-item label="身份验证代码" prop="code" class="u-m-b-15">
          <el-input v-model="twoFactorForm.code" placeholder="XXXXXX" />
        </el-form-item>
      </el-form>
      <div class="u-font-14">打开您的双因素身份验证器 (TOTP) 应用程序或浏览器扩展程序以查看您的身份验证代码</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" :loading="loadingFwoFactor" @click="submitTwoFactorForm" style="width: 100%">验
            证</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { ElMessage } from 'element-plus'
import { ref, onBeforeMount, getCurrentInstance, watch } from "vue";
const $app = getCurrentInstance().proxy
const timer = ref()
onBeforeMount(() => {
  $app.$store.commit("setOpenMsg", false)
  $app.$store.commit("setMessageType", false)
  $app.$store.commit("setUrgentType", false)
  getCode()

  timer.value = setInterval(() => {
    getCode()
  }, 1000 * 60 * 2)
})

watch(() => $app.$route.path, () => {
  clearInterval(timer.value);
})

const codeUrl = ref()
const loginForm = ref({
  username: "",
  password: "",
  rememberMe: false,
  code: null,
  uuid: ""
})
const loginRules = ref({
  username: [
    { required: true, trigger: "blur", message: "请输入您的账号" }
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" }
  ],
  code: [{ required: true, trigger: "change", message: "请输入验证码" }]
})
const loginRef = ref()
const twoFactorOpen = ref(false),
  twoFactorForm = ref({
    key: null,
    code: null
  }),
  twoFactorRules = ref({
    code: [{ required: true, trigger: "change", message: "请输入身份验证代码" }]
  }),
  loadingFwoFactor = ref(false),
  twoFactorFormaRef = ref()

const loading = ref(false)
const handleLogin = () => {
  loginRef.value.validate((valid) => {
    if(valid) loading.value = true
    if (valid) $app.$http.login.login(loginForm.value).then(res => {
      loading.value = false
      if (res.token) {
        $app.$store.commit("setToken", res.token)
        $app.$router.push({ name: "Dashboard" })
      } else {
        twoFactorForm.value.key = res.twoFactor;
        twoFactorOpen.value = true;
      }
    }).catch(e => {
      loading.value = false
      getCode()
    })
  })
}

const submitTwoFactorForm = () => {
  twoFactorFormaRef.value.validate(valid => {
    if (valid) {
      loadingFwoFactor.value = true;
      $app.$http.login.loginTwoFactor(twoFactorForm.value).then(res => {
        $app.$store.commit("setToken", res.token)
        loadingFwoFactor.value = false;
        twoFactorOpen.value = false;
        $app.$router.push({ name: "Dashboard" })
      }).catch(e => {
        loadingFwoFactor.value = false;
        getCode()
      })
    }
  })
}
const getCode = () => {
  $app.$http.login.getCodeImg().then(res => {
    codeUrl.value = "data:image/gif;base64," + res.img;
    loginForm.value.uuid = res.uuid;
  })
}

const registerShow = ref(false)
const registerForm = ref({
  email: "",
  emailCode: "",
  password: "",
  confirmPassword: "",
})
const codeTime = ref(0)
const equalToPassword = (rule, value, callback) => {
  if (registerForm.value.password !== value) {
    callback(new Error("两次输入的密码不一致"));
  } else {
    callback();
  }
};
const registerRules = ref({
  email: [
    { required: true, trigger: "blur", message: "请输入您的邮箱" }
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" }
  ],
  emailCode: [{ required: true, trigger: "change", message: "请输入验证码" }],
  confirmPassword: [
    { required: true, trigger: "blur", message: "请再次输入您的密码" },
    { required: true, validator: equalToPassword, trigger: "blur" }
  ],
})
const registerRef = ref()
const getEmailCode = () => {
  if (!registerForm.value.email) {
    ElMessage({
      message: '请先填写邮箱/账号',
      type: 'warning',
    })
    return;
  }
  if (registerForm.value.email && !codeTime.value) {
    codeTime.value = 60
    let codeInt = setInterval(() => {
      codeTime.value--
      if (codeTime.value == 0) clearInterval(codeInt)
    }, 1000);
    $app.$http.login.getCodeEmail(registerForm.value.email).then(res => {
      $app.$message({
        message: res.msg,
        type: 'success'
      });
    })
  }
}
const handleRegister = () => {
  registerRef.value.validate((valid) => {
    if (valid) $app.$http.login.retrieve(registerForm.value).then(res => {
      registerShow.value = true
      ElMessage({
        message: '密码重置成功！请重新登录',
        type: 'success',
      })
    }).catch(e => {
      getEmailCode()
    })
  })
}
// const simulateKeyUp = () => {
//   const event = new KeyboardEvent("keyup", {
//     key: "Enter",
//     code: "Enter",
//     keyCode: 13,
//     which: 13,
//     bubbles: true,
//     cancelable: true,
//   });
//   window.dispatchEvent(event);
// };
</script>
<style lang="scss" scoped>
.left {
  width: 50%;

  .left-wrap {
    height: 100%;
    display: flex;
    justify-content: center;

    // background: linear-gradient(269deg, rgb(255, 255, 255) 0%, rgb(246, 247, 248) 100%);
    li {
      align-items: center;
    }

    li img {
      width: 56px;
      height: 56px;
      object-fit: contain;
    }

    .title {
      color: #ffffff;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0px 0px 8px;
      line-height: 26px;
      font-size: 20px;
    }

    .text {
      width: 300px;
      font-weight: 400;
      line-height: 1.5;
      color: #ffffff;
      margin: 0px;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .left {
    display: none;
  }
}
</style>
