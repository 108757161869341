import request from '../request'

// 查询钱包明细列表
export function listWalletDetail(query) {
  return request({
    url: '/product/walletDetail/list',
    method: 'get',
    params: query
  })
}

// 查询钱包明细详细
export function getWalletDetail(id) {
  return request({
    url: '/product/walletDetail/' + id,
    method: 'get'
  })
}

// 新增钱包明细
export function addWalletDetail(data) {
  return request({
    url: '/product/walletDetail',
    method: 'post',
    data: data
  })
}

// 修改钱包明细
export function updateWalletDetail(data) {
  return request({
    url: '/product/walletDetail',
    method: 'put',
    data: data
  })
}

// 删除钱包明细
export function delWalletDetail(id) {
  return request({
    url: '/product/walletDetail/' + id,
    method: 'delete'
  })
}

// 导出钱包明细
export function exportWalletDetail(query) {
  return request({
    url: '/product/walletDetail/export',
    method: 'get',
    params: query
  })
}