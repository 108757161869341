import request from '../request'

// 查询卡片充值订单列表
export function listRechargeOrder(query) {
  return request({
    url: '/card/rechargeOrder/list',
    method: 'get',
    params: query
  })
}

// 查询卡片充值订单详细
export function getRechargeOrder(id) {
  return request({
    url: '/card/rechargeOrder/' + id,
    method: 'get'
  })
}

// 导出卡片充值订单
export function exportRechargeOrder(query) {
  return request({
    url: '/card/rechargeOrder/export',
    method: 'get',
    params: query
  })
}

// 取消订单
export function cancelRechargeOrder(id) {
  return request({
    url: '/card/rechargeOrder/cancel/' + id,
    method: 'delete'
  })
}
// 充值失败
export function failRechargeOrder(id) {
  return request({
    url: '/card/rechargeOrder/fail/' + id,
    method: 'delete'
  })
}
