import request from './request'

// 查询货币汇率列表
export function listCurrencyExchange(query) {
  return request({
    url: '/product/currencyExchange/list',
    method: 'get',
    params: query
  })
}

// 查询货币汇率详细
export function getCurrencyExchange(id) {
  return request({
    url: '/product/currencyExchange/' + id,
    method: 'get'
  })
}

// 查询货币汇率详细，根据原币种和目标币种
export function getCurrencyExchangeBySourceCurrencyAndTargetCurrency(sourceCurrency, targetCurrency) {
  return request({
    url: `/product/currencyExchange/${sourceCurrency}/${targetCurrency}`,
    method: 'get'
  })
}

// 新增货币汇率
export function addCurrencyExchange(data) {
  return request({
    url: '/product/currencyExchange',
    method: 'post',
    data: data
  })
}

// 修改货币汇率
export function updateCurrencyExchange(data) {
  return request({
    url: '/product/currencyExchange',
    method: 'put',
    data: data
  })
}

// 删除货币汇率
export function delCurrencyExchange(id) {
  return request({
    url: '/product/currencyExchange/' + id,
    method: 'delete'
  })
}

// 导出货币汇率
export function exportCurrencyExchange(query) {
  return request({
    url: '/product/currencyExchange/export',
    method: 'get',
    params: query
  })
}
