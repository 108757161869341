<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 navbg ms-3 my-3 border-radius-xl fixed-start sidebarType">
      <div class="zindex">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav" 
      ></i>
      <a class="m-0 navbar-brand text-center" href="/">
        <img
          class="logo"
          src="../../assets/logo/logo.png"
          alt="main_logo"
          style="width: 30px;"
        />
        <!-- <div class="text-center"> -->
          <span class="font-weight-bold"
          style="font-size: 16px;color: #fff">
          ITT CARD
            <!-- <img
            class="text-logo"
            src="../../assets/img/login/logo-w.png"
            alt="main_logo"
            /> -->
          </span>
      <!-- </div> -->
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2 zindex" />
    <sidenav-list />
    <!-- <div class="advertising zindex" v-if="showAdv">
      <span class="close" @click="handleAdv()"><el-icon><Close /></el-icon></span>
      <div>
        <el-carousel height="150px" :autoplay="true" :interval="3000" indicator-position="none">
          <el-carousel-item v-for="(item,index) in 3" :key="index" @click="handleAdvDetail(item)">
            <el-image class="w-100 h-100 img" fit="cover" :src="require('../../assets/img/home-decor-'+ item + '.jpg')" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="advnone zindex w-80" @click="handleAdv()" v-else>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 21h12.4a.6.6 0 0 0 .6-.6V3.6a.6.6 0 0 0-.6-.6H3.6a.6.6 0 0 0-.6.6V16m.5 4.5L12 12m0 0v4m0-4H8"/></svg>
    </div> -->
    <el-dialog v-model="showAdvDetail" title="广告详情" width="600px" draggable top="1vh">
      <div>
        <el-image class="w-100 img" fit="cover" :src="require('../../assets/img/home-decor-'+ advDetailText + '.jpg')" alt="" />
        <div class="text-center">广告{{ advDetailText }}</div>
      </div>
    </el-dialog>
  </aside>
</template>
<script setup>
import SidenavList from "./SidenavList.vue";
import { getCurrentInstance, ref } from "vue";
const $app = getCurrentInstance().proxy

const 
  showAdv = ref(true),
  showAdvDetail = ref(false),
  advDetailText = ref('')

const 
  handleAdv = () => {
    showAdv.value = !showAdv.value
  },
  handleAdvDetail = (i) => {
    showAdvDetail.value = true
    advDetailText.value = i
  }
</script>
<style lang="scss">
.advertising{
  background: #fff;
  margin: 0 auto;
  height: 150px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute !important;
  left: 10%;
  right: 10%;
  bottom: 20px;
  .img{
    cursor: pointer;
  }
  .close{
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 2px;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    border-radius: 99999%;
  }
  .close:hover{
    color: #e3306f;
  }
}
.advnone{
  position: absolute !important;
  bottom: 1rem;
  left: 1rem;
  color: #fff;
  cursor: pointer;
}
.logo{
  width: 50px; 
  max-height: 10rem !important;
}
.text-logo{
  width: 90px; 
  max-height: 10rem !important;
}
@media screen and (max-width:600px) {
  .navbar-brand{
    padding: 1rem 2rem !important;
    background: rgba(255, 255, 255, .1) !important;
  }
  .logo{
    width: 33px; 
    max-height: 10rem;
  }
  .text-logo{
    width: 66px; 
    max-height: 10rem;
  }
  .navbar-expand-xs{
    width: 180px !important;
  }
}
</style>
