<template>
  <div class="form-check p-0">
    <input :id="id" class="form-check-input" type="checkbox" :name="name" v-model="input" />
    <label :for="id" class="custom-control-label">
      <slot />
    </label>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
const emit = defineEmits();
const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const input = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>
