import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAITGPYgsyTRcVAlPx+iN3xns9Whw1ImMg1UtHNr8NGZcTiBYh4Rz4PUm5UD8f3LKh2p96EbxpQ86LgOIly23yw0CAwEAAQ=='

const privateKey = 'MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAhMY9iCzJNFxUCU/H6I3fGez1aHDUiYyDVS0c2vw0ZlxOIFiHhHPg9SblQPx/csqHan3oRvGlDzouA4iXLbfLDQIDAQABAkAfAlWYmi0bp3yQ+CsOKyQx39+o1sEspr59hB27Hy/1v70qve+55OftW2Ive7vHdiJZHU2uiPjnuApDOvqHjihZAiEA4jPeQroEC5JK+1ZfU32k50LwyBOTEIDTUtS6XSYvKYsCIQCWQ7uOnqwmCC9hqAO2DniFLBKump8d08vx+aGbbfuAxwIhAIFV5t+API/hNb4/xIGYdZFFVScRCiWFg075ExXvn0fBAiBVHcrpL6It7OcB900a3DmSnfN7KiTFnYph59iOFY+oUwIgMU+74jxzAZP1OKzR8NsRxqCc5ZnGTp9e6WYg2msog9A='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

