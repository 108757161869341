<template>
  <nav class="navbar navbar-main navbar-expand-lg nav-fixed" v-bind="$attrs" id="navbarBlur"
    data-scroll="true">
    <div class="p-1 container-fluid">
      <div class="d-flex justify-content-between nav-left">
        <breadcrumbs :currentPage="currentRouteName" :color="color" />
        <div class="d-flex align-items-center">
          <el-button class="left-out" @click="openUrl" size="small" style="padding: 5px;">联系客服 </el-button>
          <!-- <el-button class="left-out" @click="openOld" size="small" style="padding: 5px;">切换旧版</el-button> -->
          <el-button class="left-out" @click="showLogout = true" size="small" style="padding: 5px;">退出登录</el-button>
        </div>
      </div>
      <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4" :class="isRTL ? 'px-0' : 'me-sm-4'" id="navbar">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <!-- <material-input id="search" label="Search here" /> -->
        </div>
        <ul class="navbar-nav justify-content-end flex-wrap">
          <li class="nav-item d-flex align-items-center">
            <el-icon><UserFilled /></el-icon>
            <span class="font-weight-bolder ms-1" v-if="$store.state.user">{{ $store.state.user.userName }}</span>
          </li>
          <li class="ps-3 nav-item align-items-center right-out">
            <el-button  @click="openUrl" icon="Promotion">联系客服</el-button>
          </li>
          <!-- <li class="px-3 nav-item align-items-center right-out">
            <el-button  @click="openOld" icon="Switch">切换旧版</el-button>
          </li> -->
          <li class="px-3 nav-item align-items-center right-out">
            <el-button  @click="showLogout = true" icon="SwitchButton">退出登录</el-button>
          </li>
          <li class="nav-item dropdown d-flex align-items-center ms-3">
            <a href="#" class="p-0 nav-link lh-1" :class="[color ? color : 'text-body', showMenu ? 'show' : '']"
              id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
              <i class="material-icons cursor-pointer"> notifications </i>
            </a>
            <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton">
              <li class="mb-2" v-for="(v, k) in noticeList" :key="k">
                <router-link :to="'/notifications?id=' + v.noticeId + '&status=0'" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ v.noticeTitle }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ v.updateTime || v.createTime }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none d-flex align-items-center ms-3">
            <a href="#" @click="toggleSidebar" class="p-0 nav-link text-body lh-1" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog title="系统提示" v-model="showLogout" width="500px" append-to-body>
      <div class="d-flex align-items-center">
        <el-icon size="24" color="#fe9c16">
          <WarningFilled />
        </el-icon>
        <span class="ms-2">确定注销并退出系统吗？</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showLogout = false">取消</el-button>
          <el-button type="primary" @click="logout">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </nav>
</template>
<script setup>
import MaterialInput from "@/components/MaterialInput.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { computed, ref, getCurrentInstance, onMounted } from 'vue'
const $app = getCurrentInstance().proxy

const props = defineProps(["minNav", "color"]);
const showMenu = ref(false)
const navbarMinimize = () => $app.$store.commit("navbarMinimize")
const toggleConfigurator = () => $app.$store.commit("toggleConfigurator")
const toggleSidebar = navbarMinimize
const isRTL = computed(() => $app.$store.state.isRTL)
const isAbsolute = computed(() => $app.$store.state.isAbsolute)
const currentRouteName = computed(() => $app.$route.meta.title ?? $app.$route.name)
const noticeList = ref([])
const getNotice = () => {
  $app.$http.notice.listNotice({ status: 0, noticeType: 1, orderByColumn: "updateTime", isAsc: "DESC" }).then((res) => {
    noticeList.value = res.rows
    // res.rows.map(r => {
    //   setTimeout(() => {
    //     $app.$notify.info({
    //       title: 'ITT CARD 通告 ' + (r.updateTime || r.createTime),
    //       dangerouslyUseHTMLString: true,
    //       message: r.noticeContent,
    //       duration: 0,
    //       offset: 40,
    //       customClass: 'uw400'
    //     })
    //   }, 100);
    // })
  })
}
onMounted(() => {
  $app.$http.config.getConfigKey('sys_cs_url').then(res => {
    sys_cs_url.value = res.msg;
  })
  $app.$store.state.token ? getNotice() : null
})

const showLogout = ref(false)
const sys_cs_url = ref('')

const 
  logout = () => {
    $app.$http.login.logout().then()
    showLogout.value = false
    $app.$store.commit("logOut")
  },
  openOld = () => {
    window.open('https://mycards.ITTCARD.com/')
  },
  openUrl = () => {
    window.open(sys_cs_url.value)
  }
// props.minNav;
</script>
<style lang="scss">
.uw400 {
  width: 400px !important;
}
.nav-fixed{
  position: fixed !important;
  left: calc(250px + 1rem);
  right: 0;
  background: #f0f2f5;
  z-index: 99;
  // border-bottom: 1px solid #ccc;
  box-shadow: 8px 2px 12px 0 rgba(0, 0, 0, 0.16) !important;
}
.left-out{
  display: none;
}
.right-out{
  display: flex;
}
@media screen and (max-width: 600px) {
  .nav-left{
    width: 100%;
  }
  .left-out{
    display: block;
  }
  .right-out{
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .nav-fixed{
    position: fixed !important;
    left: 0;
  }
}
</style>