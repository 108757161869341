import request from './request'

// 登录方法
export function login(data) {
    return request({
      url: '/login',
      headers: {
        isToken: false
      },
      method: 'post',
      data: data
    })
  }
  
  // 注册方法
  export function register(data) {
    return request({
      url: '/register',
      headers: {
        isToken: false
      },
      method: 'post',
      data: data
    })
  }
  
  // 找回密码
  export function retrieve(data) {
    return request({
      url: '/retrieve',
      headers: {
        isToken: false
      },
      method: 'post',
      data: data
    })
  }
  
  // 获取用户详细信息
  export function getInfo() {
    return request({
      url: '/getInfo',
      method: 'get'
    })
  }
  
  // 退出方法
  export function logout() {
    return request({
      url: '/logout',
      method: 'post'
    })
  }
  
  // 获取验证码
  export function getCodeImg() {
    return request({
      url: '/captchaImage',
      headers: {
        isToken: false
      },
      method: 'get',
      timeout: 20000
    })
  }
  
  // 获取邮箱验证码
  export function getCodeEmail(d) {
    return request({
      url: '/captchaEmail',
      headers: {
        isToken: false
      },
      method: 'get',
      params: d,
      timeout: 120000
    })
  }
  
export function loginTwoFactor(data) {
  return request({
    url: '/login/twoFactor',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}