import request from './request'

// 查询卡片账号列表
export function listCardAccount(query) {
  return request({
    url: '/card/cardAccount/list',
    method: 'get',
    params: query
  })
}

export function listCardAccountByCardNo(cardNo) {
  return request({
    url: '/card/cardAccount/list/cardNo',
    method: 'get',
    params: {cardNo}
  })
}

// 查询卡片账号详细
export function getCardAccount(id) {
  return request({
    url: '/card/cardAccount/' + id,
    method: 'get'
  })
}

// 新增卡片账号
export function addCardAccount(data) {
  return request({
    url: '/card/cardAccount',
    method: 'post',
    data: data
  })
}

// 修改卡片账号
export function updateCardAccount(data) {
  return request({
    url: '/card/cardAccount',
    method: 'put',
    data: data
  })
}

// 删除卡片账号 /card/cardAccount/id delete
export function delCardAccount(id) {
  return request({
    url: '/card/cardAccount/' + id,
    method: 'delete'
  })
}

// 导出卡片账号
export function exportCardAccount(query) {
  return request({
    url: '/card/cardAccount/export',
    method: 'get',
    params: query
  })
}
// 导出卡片账号
export function exportCardList(query) {
  return request({
    url: '/card/cardAccount/clientExport',
    method: 'get',
    params: query
  })
}

// 卡片充值
export function rechargeCardAccount(data) {
  return request({
    url: '/card/cardAccount/recharge',
    method: 'put',
    params: data
  })
}

// 卡片充退
export function refundCardAccount(data) {
  return request({
    url: '/card/cardAccount/refund',
    method: 'put',
    params: data
  })
}

// 卡片注销
export function cardDestory(id){
  return request({
    url: '/card/cardAccount/destory/' + id,
    method: 'delete'
  })
}

// 卡片封停
export function sealCard(id){
  return request({
    url: '/card/cardAccount/seal/' + id,
    method: 'delete'
  })
}

export function pushRisk(code) {
  return request({
    url: '/card/cardAccount/pushRisk',
    method: 'get',
    params: {code}
  })
}

export function getUserReport() {
  return request({
    url: '/card/cardAccount/report',
    method: 'get'
  })
}

export function getRealCardNo(id) {
  return request({
    url: '/card/cardAccount/real/cardNo',
    method: 'get',
    params: {id}
  })
}

export function getRealCvv(id) {
  return request({
    url: '/card/cardAccount/real/cvv',
    method: 'get',
    params: {id}
  })
}

export function getRealExpiry(id) {
  return request({
    url: '/card/cardAccount/real/expiry',
    method: 'get',
    params: {id}
  })
}