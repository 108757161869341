import request from './request'

// 查询卡片产品列表
export function listCardProduct(query) {
  return request({
    url: '/card/cardProduct/list',
    method: 'get',
    params: query
  })
}

// 查询卡片产品详细
export function getCardProduct(id) {
  return request({
    url: '/card/cardProduct/' + id,
    method: 'get'
  })
}

// 新增卡片产品
export function addCardProduct(data) {
  return request({
    url: '/card/cardProduct',
    method: 'post',
    data: data
  })
}

// 修改卡片产品
export function updateCardProduct(data) {
  return request({
    url: '/card/cardProduct',
    method: 'put',
    data: data
  })
}

// 删除卡片产品
export function delCardProduct(id) {
  return request({
    url: '/card/cardProduct/' + id,
    method: 'delete'
  })
}

// 导出卡片产品
export function exportCardProduct(query) {
  return request({
    url: '/card/cardProduct/export',
    method: 'get',
    params: query
  })
}