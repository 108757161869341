<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <el-row class="row">
          <el-col :xs="24" class="col-md-3">
            <div class="card h-100">
              <div class="p-3 mx-4 text-center card-header d-flex justify-content-center">
                <div class="avatar avatar-xl position-relative" style="border-radius: 8px;overflow: hidden;">
                  <img class="w-100" :src="require('@/assets/img/avatar/Level' + userInfo.userLevel + '.svg')">
                </div>
              </div>
              <div class="p-3 pt-0 text-center card-body">
                <span class="mb-0 text-center font-weight-bolder">{{ userInfo.nickName }}</span>
                <div class="p-2" style="background: #efefef;border-radius: 8px;margin: 5px;">
                  <div style="font-weight: 700;">
                    <p class="mb-0 font-weight-bolder" v-html="userInfo.vipLabel"></p>
                  </div>
                </div>
                <span class="mb-0 font-weight-bolder">钱包余额 ${{ filterNumber(userInfo.price) }}</span>
                <div class="d-flex justify-content-center mt-1">
                  <div style="width: 100px;" class="pt-1 pb-1" @click="openVipOrder()"
                    v-if="userInfo.userLevel > 0 && daysRemaining < 30">
                    <material-button variant="gradient" color="primary" fullWidth>续费方案</material-button>
                  </div>
                  <div style="width: 100px;" class="pt-1 pb-1" v-else>
                    <router-link :to="{ name: 'Recharge' }">
                      <material-button variant="gradient" color="primary" fullWidth>充 值</material-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :span="18">
            <div class="row mt-0">
              <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4" style="min-width: 260px;">
                <div class="card">
                  <div class="card-header p-3 pt-2 pb-1 h-100" style="border-radius: 0.75rem;">
                    <div
                      class="icon shadow text-center border-radius-xl position-absolute d-flex align-items-center justify-content-center"
                      :class="`bg-gradient-success shadow-success success-icon`" style="width: 50px;height: 50px;">
                      <el-icon color="#52ac56" class="no-inherit" size="24">
                        <CreditCard />
                      </el-icon>
                    </div>
                    <div class="pt-1 text-end" style="height: 61.59px;">
                      <div class="text-capitalize font-weight-bolder">账户开卡数：{{ userInfo.haveCardCount }}</div>
                      <span class="font-weight-bolder text-xs">
                        剩余卡片数量：{{ userInfo.cardCapacity || 0 }}(可增加)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4" style="min-width: 260px;">
                <mini-statistics-card :title="{ text: '账户拒付率', value: filterNumber(billSum.authFailRate) + '%' }"
                  :cName="billSum.authFailRate > 12 ? 'danger-color' : ''" :icon="{
                    name: 'WarningFilled',
                    color: 'text-white',
                    background: 'warning',
                    class: 'warning-icon'
                  }" color='' />
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-3 " style="min-width: 260px;">
                <mini-statistics-card :title="{ text: '交易授权撤销率', value: filterNumber(billSum.reversalRate) + '%' }" :icon="{
                  name: 'BellFilled',
                  color: '#909399',
                  background: 'dark',
                  class: 'dark-icon'
                }" color='' />
              </div>
            </div>
            <div class="card mt-3 d-flex trends card-style" style="padding: 20px 20px 0;flex-direction: inherit;">
              <div class="w-50 w50">
                <div class="font-weight-bolder">最新动态：</div>
                <div class="p-2" style="border-top: 1px solid #e9e9e9;border-right: 1px solid #e9e9e9;">
                  <el-carousel height="155px" :autoplay="true" :interval="3000" indicator-position="none"
                    v-if="noticeList.length > 0">
                    <el-carousel-item v-for="item in noticeList" :key="item.noticeId">
                      <div class="d-flex justify-content-between">
                        <div class="font-weight-bold">{{ item.noticeTitle }}</div>
                      </div>
                      <router-link :to="'/notifications?id=' + item.noticeId + '&status=0'">
                        <div class="text-xl noticeText"
                          v-html="item.noticeContent.replace(/<p>(.*?)<\/p>/g, '<span>$1</span>')"></div>
                        <!-- <span class="text-xs" style="color:#3c3c3c;">(点击查看详情)</span> -->
                      </router-link>
                      <span class="text-xs text-end" style="position: absolute;right: 0px;bottom: 10px;">
                        <div class="text-xl">
                          <i class="fa fa-clock me-1"></i>
                          {{ item.updateTime || item.createTime }}
                        </div>
                      </span>
                    </el-carousel-item>
                  </el-carousel>
                  <div class="font-weight-bold text-center" style="height: 155px;" v-else>{{ noticeListText }}</div>
                </div>
              </div>
              <div class="w-50 w50">
                <div class="font-weight-bolder">待处理事项：</div>
                <div class="p-2" v-if="userMessageList.length > 0" style="border-top: 1px solid #e9e9e9;">
                  <el-carousel height="155px" :autoplay="true" :interval="10000" indicator-position="none">
                    <el-carousel-item v-for="item in userMessageList" :key="item.id">
                      <div class="d-flex justify-content-between">
                        <div class="font-weight-bold">{{ item.title }}</div>
                        <el-tag size="small" type="success" class="mx-2 ms-0" v-if="item.isRead">已处理</el-tag>
                        <el-tag size="small" type="warning" class="mx-2 ms-0" v-else>未处理</el-tag>
                      </div>
                      <router-link :to="'/notifications?id=' + item.id + '&status=user_msg'">
                        <div class="text-xl noticeText" v-html="item.content"></div>
                      </router-link>
                      <span class="text-xs text-end" style="position: absolute;right: 0px;bottom: 10px;">
                        <div class="text-xl">
                          <i class="fa fa-clock me-1"></i>
                          {{ item.updateTime || item.createTime }}
                        </div>
                      </span>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="p-2" v-else style="border-top: 1px solid #e9e9e9;">
                  <div class="font-weight-bold text-center">{{ userMessageText }}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="card-tis">
          <div class="tis-item">

            <div class="title">
              <div class="icon" style="color:#ffa726">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 2 24 24">
                  <path fill="currentColor"
                    d="M10.125 22q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.613-.3t-.562-.375l-2.175.9q-.35.15-.7.038t-.55-.438L2.4 15.4q-.2-.325-.125-.7t.375-.6l1.875-1.425Q4.5 12.5 4.5 12.337v-.674q0-.163.025-.338L2.65 9.9q-.3-.225-.375-.6t.125-.7l1.85-3.225q.2-.325.55-.438t.7.038l2.175.9q.275-.2.575-.375t.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3t.562.375l2.175-.9q.35-.15.7-.038t.55.438L21.6 8.6q.2.325.125.7t-.375.6l-1.875 1.425q.025.175.025.338v.674q0 .163-.05.338l1.875 1.425q.3.225.375.6t-.125.7l-1.85 3.2q-.2.325-.563.45t-.712-.025l-2.125-.9q-.275.2-.575.375t-.6.3l-.3 2.325q-.05.375-.325.625t-.65.25h-3.75Zm1.925-6.5q1.45 0 2.475-1.025T15.55 12q0-1.45-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12q0 1.45 1.012 2.475T12.05 15.5Z" />
                </svg>
              </div>
              新手教程
            </div>
            <div class="text">初次使用，通过《新手教程》学习平台的使用方法，有效提高上手速度，提高使用效率</div>
            <div class="bot">
              <div style="width: 100px;" @click="detailOne = true" class="pt-1 pb-1">
                <material-button class="dark-but" variant="gradient" color="dark" fullWidth>详细了解</material-button>
              </div>
            </div>
          </div>
          <div class="tis-item ">
            <div class="title d-flex align-items-center">
              <div class="icon mt-n1"><el-icon size="20" color="#ffa726">
                  <InfoFilled />
                </el-icon>
              </div>
              <span class="ms-2">风控规则</span>
            </div>
            <div class="text">详细了解风控规则，避免因触发风控而导致处罚，安全、合法、合规的使用ITT CARD，助力您的跨境事业</div>
            <div class="bot">
              <div style="width: 100px;" @click="detailTwo = true" class="pt-1 pb-1">
                <material-button class="dark-but" variant="gradient" color="dark" fullWidth>详细了解</material-button>
              </div>
            </div>
          </div>
          <div class="tis-item">
            <div class="title d-flex align-items-center">
              <div class="icon mt-n1"><el-icon size="20" color="#ffa726">
                  <BellFilled />
                </el-icon>
              </div>
              <span class="ms-2">禁止交易商户</span>
            </div>
            <div class="text">根据国家的法律法规，以及发卡行和卡组织的规定，禁止列表内的交易对手，请用户务必产格道</div>
            <div class="bot">
              <div style="width: 100px;" @click="detailThree = true" class="pt-1 pb-1">
                <material-button class="dark-but" variant="gradient" color="dark" fullWidth>详细了解</material-button>
              </div>
            </div>
          </div>
          <div class="tis-item">
            <div class="title d-flex align-items-center">
              <div class="icon mt-n1"><el-icon size="20" color="#ffa726">
                  <QuestionFilled />
                </el-icon>
              </div>
              <span class="ms-2">常见问题FAQ</span>
            </div>
            <div class="text">当您遇到使用上的问题，可通过这里查询到解决方法</div>
            <div class="bot">
              <div style="width: 100px;" @click="detailFour = true" class="pt-1 pb-1">
                <material-button class="dark-but" variant="gradient" color="dark" fullWidth>详细了解</material-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row report mt-4">
          <div class="col-lg-4 report-item col-md-6 mt-4">
            <chart-holder-card :title="billSum.topUpAmount" subtitle="总充值金额" update="campaign sent 2 days ago"
              color="white" shadow="dark">
              <reports-bar-chart color="#52ac56" id="bar-chart" :chart="{
                labels: toRaw(successDate),
                datasets: {
                  label: '$',
                  data: toRaw(successAmount),
                },
              }" />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 report-item col-md-6 mt-4">
            <chart-holder-card :title="billSum.authSuccessAmount" subtitle="交易授权成功金额" update="updated 4 min ago"
              color="white" shadow="dark">
              <reports-line-chart color="#52ac56" id="line-one" :chart="{
                labels: toRaw(topupDate),
                datasets: {
                  label: '$',
                  data: toRaw(topupAmount),
                },
              }" />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 report-item mt-4">
            <chart-holder-card :title="billSum.authReversalAmount" subtitle="交易退款金额" update="just updated" color="white"
              shadow="dark">
              <reports-line-chart color="#909399" id="line-two" :chart="{
                labels: toRaw(refundDate),
                datasets: {
                  label: '$',
                  data: toRaw(refundAmount),
                },
              }" />
            </chart-holder-card>
          </div>
        </div>

      </div>
    </div>
    <!-- 详细了解 -->
    <el-dialog v-model="detailOne" title="新手教程" width="800px" draggable top="1vh">
      <div class="detail">
        <p class="font-weight-bolder">ITT CARD平台注册时候的邮箱保存好，这个非常重要。后续的信息修改都是以邮箱资料为准。</p>
        <div class="body">
          <div class="text-xl mb-2">1.账号注册完登录后，需充值才会能拥有相关使用和查询权限。</div>
          <div class="text-xl mb-2">2.账户充值：快速充值—选择要充值的金额支付（特别注意，按照最后显示的金额支付，精确到小数点后三位。关联到账单确认，如果输入错误，没办法入金！）</div>
          <div class="text-xl mb-2">3.账户充值到账时间一般为1-5分钟内完成，如果完全按要求充值，超过5分钟尚未到账，可联系客服查询！</div>
          <div class="text-xl mb-2">4.快速开卡：需选择使用场景后-再选择卡bin进行开卡。</div>
          <div class="text-xl mb-2">5.账户安全：在平台的“账户/安全”里会有多种账户安全设置。客户可根据自己的实际情况进行设置。</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailOne = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="detailTwo" title="风控规则" width="800px" draggable top="1vh">
      <div class="detail">
        <p class="font-weight-bolder">ITT CARD用卡规则和风控条款</p>
        <p class="font-weight-bolder">ITT CARD虚拟信用卡，禁止恶意退款、拒付、薅羊毛、纯验证不消费等用卡行为，禁止用于洗钱等违法违规行为，违者将被扣除罚金，冻结卡余额，封禁帐号，并且不做提前告知。
        </p>
        <div class="body">
          <div class="text-xl">风控条款</div>
          <div class="text-xl mb-2">触发以下规则，冻结信用卡或销卡不退款处理，并处罚金，严重者封禁帐号：</div>
          <div class="text-xl mb-2">1.单卡连续交易授权失败超过5次，强制封卡不退款！</div>
          <div class="text-xl mb-2">2.单卡拒付率超过12%（拒付率=拒付总笔数/总成功交易笔数），强制封卡不退款！</div>
          <div class="text-xl mb-2">3.买退，任何场景下的恶意退款，强制封卡不退款！</div>
          <div class="text-xl mb-2">4.账户拒付率超12%，强制封户不退款！</div>
          <div class="text-xl">5.平台开出的账户，消耗不达1W刀，账户余额低于200刀的，不支持销户退款。</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailTwo = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="detailThree" title="禁止交易商户" width="800px" draggable top="1vh">
      <div class="detail">
        <table class="w-100 align-items-center justify-content-center mb-0 text-center">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-sm font-weight-bolder">平台</th>
              <th class="text-uppercase text-secondary text-sm font-weight-bolder">备注</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span class="text-sm mb-0">Audible</span></td>
              <td><span class="text-sm mb-0">亚马逊在线有声书</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">Wise </span></td>
              <td><span class="text-sm mb-0">汇款工具</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">dundle.com</span></td>
              <td><span class="text-sm mb-0">供数字预付卡和礼品卡</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">MYGIFTCARDSUPPLY</span></td>
              <td><span class="text-sm mb-0">礼品卡</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">MoonPay</span></td>
              <td><span class="text-sm mb-0">支付平台</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">easy shop，deliveroo，doordash，uber east</span></td>
              <td><span class="text-sm mb-0">外卖平台</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">RULE OF LAW SOCIETY IV</span></td>
              <td><span class="text-sm mb-0">慈善机构</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">AMAZON DIGITAL GERMANY GMBH</span></td>
              <td><span class="text-sm mb-0">欺诈退货款 </span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">AMAZON MEDIA EU</span></td>
              <td><span class="text-sm mb-0">欺诈退货款 </span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">okcoin，huobi（火币）</span></td>
              <td><span class="text-sm mb-0">虚拟货币 </span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">skyhost</span></td>
              <td><span class="text-sm mb-0">数字货币、加密钱包交易的销售数字艺术品 </span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">tinder，gotinder</span></td>
              <td><span class="text-sm mb-0">色情社交软件 </span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">SWAG.LIVE</span></td>
              <td><span class="text-sm mb-0">亚洲色情网站</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">ONLYFANS</span></td>
              <td><span class="text-sm mb-0">敏感网站</span></td>
            </tr>
            <tr>
              <td><span class="text-sm mb-0">Steam</span></td>
              <td><span class="text-sm mb-0">游戏平台</span></td>
            </tr>
          </tbody>
        </table>
        <p class="font-weight-bolder mb-0">以上为ITT CARD所有禁止交易商户，针对触发发卡行严令禁止的交易场景，一旦触发必封卡不退款！！！情节严重直接进行封户不退款！！！</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailThree = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="detailFour" title="常见问题FAQ" width="800px" draggable top="1vh">
      <div class="detail">
        <p class="font-weight-bolder">《跨境必备工具推荐-虚拟卡Virtual Card》---ITT CARD</p>
        <div class="body">
          <p class="font-weight-bolder mb-2">一，什么是虚拟卡Virtual Card？</p>
          <div class="text-xl  mb-3">虚拟卡Virtual
            Card，即是一张不记名的具有信用卡（或者储蓄卡，具体看卡的级别）功能的非实体卡片，他与所有的信用卡一样具有三要素，分别是：卡号、安全码（CVV）、有效期。这是在进行互联网支付时，必须要填写的信息。虚拟信用卡与常用的实体银行卡功能一致，两者唯一区别是卡片是否实体。
          </div>
          <div class="text-xl  mb-3">
            虚拟卡可以批量无限开卡，可以自定义卡片的信息或者是直接由系统生成一套信息，这是为了保护用户个人的隐私安全问题，毕竟国外的环境和国内不一样，很容易被盗刷，所以虚拟卡也有保障持卡人资金安全的用处。当然，作为跨境人来说，虚拟卡的用处就非常多了，可以说是跨境项目，离不开虚拟卡。
          </div>
          <p class="font-weight-bolder mb-2">二，ITT CARD虚拟信用卡平台的优势</p>
          <div class="text-xl  mb-3 text-danger">
            1.支持USD直接充值使用，整体费率低！<br>
            2.无限开卡，随开随用，不用的卡注销，余额自动回到账号。<br>
            3.费用透明，账单清晰，没有任何隐藏收费。<br>
            4.多平台多渠道支持使用扣费，可操作性强。
          </div>
          <p class="font-weight-bolder mb-2">三，ITT CARD虚拟信用卡平台介绍</p>
          <div class="text-xl">目前平台拥有卡BIN：</div>
          <div class="text-xl  mb-3">491724 406594 532959 486695
            472593/436797 467413 557271 441112 559666
            平台不定期上线新卡头。
            开户链接: https://ITTCARD.com/
            支持自定义持卡人姓名和账单地址，自己注册，充值，开卡。 适用于各种跨境电商业务。
          </div>
          <p class="font-weight-bolder mb-2">支持的支付场景</p>
          <div class="text-xl mb-3">
            1.支持美国亚马逊、Ebay、速卖通、Yeezy Supply、FootLocker、Supreme 等，方便海淘用户或者个人外贸买买买。<br>
            2.支持验证 Google Play 开发者账号、支持绑定到 AppStore。<br>
            3.支持在各个平台上进行广告付款，比如支持 Facebook、Twitter、Instagram、Google、Quora 等广告平台。<br>
            4.支持各种娱乐场景付款，比如：Netflix 会员、switch 游戏、Spotify、Amazon Prime Viedo等。
          </div>
          <p class="font-weight-bolder mb-2">ITT CARD虚拟信用卡平台费率介绍</p>
          <div class="text-xl">
            1.平台是按照会员等级区分收费。<br>
            2.分别为个人用户，个人专业，企业用户，合伙人。<br>
            3.充值费率：3%-2.8%-2.5% .<br>
            4.部分卡bin根据银行渠道要求，会有收取相应交易授权费。
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailFour = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 续费方案 -->
    <el-dialog v-model="dialogVisible" title="续费方案" width="600px" draggable top="1vh">
      <div class="">
        <div class="title d-flex flex-wrap">
          <div style="margin-right: 1.5rem;">方案等级：<span class="theme-color">{{ vipSubscription.cardVipName }}</span></div>
          <div class="d-flex flex-wrap">到期时间：
            <span class="theme-color" v-if="vipSubscription.isForever === '1'">无限期</span>
            <span class="theme-color" v-else>{{ vipSubscription.expireDate }}</span>
          </div>
        </div>
        <el-divider></el-divider>
        <el-form ref="vipOrderForm" label-position="top" label-width="80px" :model="form">
          <el-form-item label="续费时长">
            <el-check-tag class="mt-2" checked>{{ form.duration }}</el-check-tag>
          </el-form-item>
          <el-form-item label="选择方案">
            <div class="d-flex flex-wrap">
              <div class="mt-2" v-for="(item, index) in cardVipList" :key="index">
                <el-check-tag style="margin-right: 20px;" :checked="item.id === form.cardVipId"
                  @change="handleVipType(item)" v-if="item.createDiscountFee > 0">
                  {{ item.name }}：<span class="font-weight-bolder">$</span>{{ filterNumber(item.createFee) }}
                </el-check-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="企业名称" prop="company" v-if="form.enableSubset"
            :rules="[{ required: true, message: '请输入企业名称', trigger: ['blur'] }]">
            <el-input v-model="form.company" :disabled="!form.companyEdit" placeholder="请输入企业名称"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="cashier_desk mt-4" type="flex">
          <el-col :span="8" class="divider">
            <div class="title">费用</div>
            <div class="">方案金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.price) }}</div>
            <div class="">折扣金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.discountFee) }}</div>
            <div class="">实付金额：<span class="font-weight-bolder">$</span>{{ filterNumber(form.actuallyFee) }}</div>
          </el-col>
          <el-col :span="16">
            <div class="payment_method">
              <div class="title">支付方式</div>
              <div class="pay_btn_group d-flex flex-wrap">
                <div class="mb-2" style="width: 130px;">
                  <material-button variant="gradient" color="primary" fullWidth style="padding: 11px;">钱包余额<span
                      class="font-weight-bolder">$</span>{{ filterNumber(balance) }}</material-button>
                </div>
                <div @click="toRecharge" class="mb-2" style="width: 130px;">
                  <material-button class="primary-but" variant="gradient" color="primary" fullWidth>快速充值</material-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <template #footer>
        <span class="dialog-footer d-flex justify-content-end">
          <div @click="dialogVisible = false" style="margin-right: 20px;width: 130px;">
            <material-button class="dark-but" style="padding: 9px;" variant="gradient" color="dark" fullWidth>取
              消</material-button>
          </div>
          <div style="min-width: 170px;" @click="dialogVisible = false">
            <material-button variant="gradient" class="primary-but" color="primary" fullWidth
              @click="submitVipOrder">立即支付（${{
                filterNumber(form.actuallyFee) }}）</material-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "@/examples/Charts/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, getCurrentInstance, onMounted, toRaw } from 'vue';
const $app = getCurrentInstance().proxy

const sys_cs_url = ref('')
const noticeList = ref([])
const userMessageText = ref('加载中...')
const noticeListText = ref('加载中...')
//了解弹框
const
  detailOne = ref(false),
  detailTwo = ref(false),
  detailThree = ref(false),
  detailFour = ref(false)
//续费方案
const dialogVisible = ref(false)
const vipSubscription = ref({})
const form = ref({
  cardVipId: 2,
  paymentMethod: 0,
  duration: '1月',
  company: '',
  price: '0.00',
  discountFee: 0,
  actuallyFee: 0,
  enableSubset: false,
  companyEdit: true,
})
const createTime = ref([])
const cardVipList = ref([])
//获取用户信息
const userInfo = ref({
  nickName: '',
  avatar: '',
  vipLabel: '个人用户 无限期',
  price: '0.00',
  cardCapacity: '',
  haveCardCount: 0,
  userLevel: 0,
})
//获取卡信息
const billSum = ref({
  topUpAmount: 0,
  authSuccessAmount: 0,
  authReversalAmount: 0,
  authFailRate: 0,
  reversalRate: 0,
  cardCapacity: 0,
})
const queryParams = ref({
  pageNum: 1,
  pageSize: 10,
  cardId: null,
  txnType: null,
  txnStatus: null,
  queryDate: null,
  status: 0
})
const daysRemaining = ref('')
onMounted(() => {
  if (!$app.$store.state.token || $app.$store.state.isOut) return

  $app.$http.config.getConfigKey('sys_cs_url').then(res => {
    sys_cs_url.value = res.msg;
  })

  if (!$app.$store.state.urgentType)
    $app.$http.notice.listNotice({ status: 0, noticeType: 3, orderByColumn: "updateTime", isAsc: "DESC" }).then((res) => {
      res.rows.map(r => {
        setTimeout(() => {
          $app.$msgbox({
            title: r.noticeTitle,
            message: '<div class="user_message_content">' + r.noticeContent + '</div>' +
              '<div class="user_message_time"><div class="text-center"><p class="font-bold">ITT CARD</p><p>' + r.createTime + '</p></div></div>',
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showConfirmButton: false,
            customClass: 'user_message_box',
            beforeClose: (action, instance, done) => {
              $app.$store.state.urgentType = true
              $app.$store.commit("setUrgentType", true)
            }
          })

        }, 100);
      })
    })
    
  $app.$http.notice.listNotice({ status: 0, noticeType: 1, orderByColumn: "updateTime", isAsc: "DESC" }).then((res) => {
    noticeList.value = res.rows
    if (noticeList.value.length == 0) {
      noticeListText.value = '暂无待处理事项！'
    }
  })
  $app.$http.dict.getDicts('card_vip_create_time_unit').then(res => {
    createTime.value = res.data
  })
  $app.$http.shop.getApiCardVipList().then(res => {
    cardVipList.value = res.rows
  })

  getUser()
  $app.$http.shop.getApiCardVipSubscription().then(res => {
    const targetDate = new Date(res.data.expireDate);
    // 获取当前日期
    const currentDate = new Date();
    // 计算两个日期之间的差异（以毫秒为单位）
    const timeDifference = targetDate - currentDate;
    // 将毫秒转换为天数
    daysRemaining.value = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    vipSubscription.value = res.data
    userInfo.value.vipLabel = res.data.cardVipName;
    if (res.data.isForever === '1') {
      userInfo.value.vipLabel += ' 无限期'
    } else {
      userInfo.value.vipLabel += '<br> ' + '还剩' + ' ' + daysRemaining.value + ' ' + '天到期';
    }
  })
  getCardReport()
  console.log($app.$store.state.messageType);
})

const getUser = () => {
  $app.$http.login.getInfo().then(res => {
    userInfo.value.nickName = res.user.nickName
    // userInfo.value.avatar = res.user.avatar ? 'VUE_APP_BASE_API' + res.user.avatar : res.user.avatar
    userInfo.value.price = res.user.balance
    userInfo.value.cardCapacity = res.user.cardCapacity
    userInfo.value.haveCardCount = res.user.haveCardCount
    userInfo.value.userLevel = res.user.userLevel || 0
    $app.$store.commit("setUser", res.user)
    getFaledList()
    getSumBill()
    getUserMessageList()
  })
}
//
const
  successAmount = ref([]),
  successDate = ref([]),
  topupAmount = ref([]),
  topupDate = ref([]),
  refundAmount = ref([]),
  refundDate = ref([])

const getCardReport = () => {
  if (!$app.$store.state.user.userLevel) return
  $app.$http.bill.getCardReport(7).then(res => {
    Object.keys(res.data.authSuccessAmount).map(function (date) {
      successDate.value.push((date.replace(/^.{5}/, '')))
      successAmount.value.push(res.data.authSuccessAmount[date])
    });
    Object.keys(res.data.cardTopupAmount).map(function (date) {
      topupDate.value.push((date.replace(/^.{5}/, '')))
      topupAmount.value.push(res.data.cardTopupAmount[date])
    });
    Object.keys(res.data.refundAmount).map(function (date) {
      refundDate.value.push((date.replace(/^.{5}/, '')))
      refundAmount.value.push(res.data.refundAmount[date])
    });
  })
}
const getSumBill = () => {
  if (!$app.$store.state.user.userLevel) return
  $app.$http.bill.sumBill(queryParams.value).then(res => {
    if (res.code != 403) billSum.value = res.data
  })
}
const openVipOrder = (item) => {
  if (item == null) {
    cardVipList.value.some(r => {
      if (r.createDiscountFee > 0) {
        item = r;
        return true;
      }
    })
  }
  dialogVisible.value = true
  handleVipType(item)
}

const dialogName = ref('')
const handleVipType = (item) => {
  form.value.cardVipId = item.id
  let unit = toRaw(createTime.value.filter(r => r.dictValue === item.createTimeUnit))[0]
  form.value.duration = '1' + unit.dictLabel;
  form.value.price = item.createFee; // 原价
  form.value.actuallyFee = item.createDiscountFee; //实付金额
  form.value.discountFee = item.createFee - item.createDiscountFee; // 折扣金额
  form.value.enableSubset = item.enableSubset;
  dialogName.value = item.name + ' ' + '$' + item.createFee + '/' + unit.dictLabel
}
const toRecharge = () => {
  $app.$router.push({ name: "Recharge" })
}
const failedList = ref([])
const failedParams = ref({
  pageNum: 1,
  pageSize: 4,
  txnStatus: 'FAILED',//'FAILED'
  status: 0
})
const getFaledList = () => {
  if (userInfo.value.userLevel == 0) return
  $app.$http.bill.listBill(failedParams.value).then(res => {
    failedList.value = res.rows || []
  })
}
const userMessageList = ref([])
const messageParams = ref({
  pageNum: 1,
  pageSize: 5
})
const getUserMessageList = () => {
  if (!$app.$store.state.user.userLevel) return userMessageText.value = '暂无待处理事项！'
  $app.$http.message.listUserMessage(messageParams.value).then(res => {
    userMessageList.value = res.rows
    if (res.rows.length == 0) {
      userMessageText.value = '暂无待处理事项！'
    }
  })
}

const vipOrderForm = ref()
const submitLoading = ref(false)
const balance = ref(0)
const submitVipOrder = () => {
  vipOrderForm.value.validate(valid => {
    if (valid) {
      $app.$confirm(`是否确认升级${dialogName.value}`, '温情提示').then(() => {
        if (form.value.actuallyFee > userInfo.value.price) {
          $app.$notify.error({
            title: '钱包余额不足',
            dangerouslyUseHTMLString: true,
            message: '<p>余额: $' + userInfo.value.value + '</p><p>费用: $' + form.value.actuallyFee + '</p>'
          });
          return;
        }
        submitLoading.value = true;
        $app.$http.shop.setVipOrder(form.value).then(res => {
          open.value = false;
          if (form.value.enableSubset) {
            $app.$alert('恭喜您成为企业用户，重新登录后享更多特权！', '续费成功', {
              confirmButtonText: '重新登录',
              type: 'success',
              callback: () => {
                $app.$store.commit('setToken', null)
                $app.$router.push({ name: "SignIn" })
              }
            });
            getUser()
          } else {
            $app.$message.success("续费成功")
            getUser()
          }
        }).finally(f => {
          submitLoading.value = false;
        });
      })
    }
  });
}
</script>
<style lang="scss" scoped>
td {
  border: 1px solid #ccc;
  padding: 10px;
}

.trends {
  a:hover {
    color: inherit !important;
  }
}

.noticeText {
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card-tis {
  display: flex;

  .tis-item {
    background: #fff;
    padding: 16px;
    border-radius: 6px;
    width: 25%;
    margin-right: 1.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.0);

    .title {
      font-size: 16px;
      font-weight: 600;
      // color: #606266;
      padding: 10px 0;
    }

    .text {
      height: 60px;
      font-size: 12px;
      padding-bottom: 10px;
    }
  }

  .tis-item:last-child {
    margin-right: 0;
  }
}

.card-tis {
  margin-top: 1.5rem;
}

.cashier_desk {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 10px 20px;

  .divider {
    border-right: 1px solid #DCDFE6;
  }

  .payment_method {
    padding-left: 30px;

    .pay_btn_group {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .title {
    color: #606266;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  .price {
    font-size: 13px;
    color: #FF0000;
    line-height: 19px;
  }
}

@media screen and (max-width: 620px) {
  .card-style {
    flex-wrap: wrap;

    .w50 {
      width: 100% !important;
    }
  }

  .d-card {
    margin-top: 1.5rem;
  }

  .mini-card {
    // margin-top: 1.5rem;
    // min-width: calc(100vw - 3rem) !important;
  }

  .card-tis {
    margin-top: 0;
  }

  .trends {
    margin-top: 30px !important;

    .el-carousel__container {
      height: 300px !important;
    }
  }

  .report-item {
    margin-bottom: 1.5rem;
  }

  .card-tis {
    flex-wrap: wrap;

    .tis-item {
      width: 100%;
      margin-right: 0;
      margin-top: 1.5rem;
    }
  }
}
</style>
