<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-center">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div class="text-sm text-center copyright text-muted">
            ©2002-
            {{ new Date().getFullYear() }} 
            <!-- <i class="fa fa-heart"></i> -->
            By
            <a
              href="https://www.ITTCARD.com"
              class="font-weight-bold"
              target="_blank"
              >ITT CARD团队</a
            >
            -专业的跨境支付一站式解决方案
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
