import request from '../request'

// 查询系统消息列表
export function listMessage(query) {
  return request({
    url: '/system/message/list',
    method: 'get',
    params: query
  })
}

export function listUserMessage(query) {
  return request({
    url: '/system/message/list/user',
    method: 'get',
    params: query
  })
}

// 查询系统消息详细
export function getMessage(id) {
  return request({
    url: '/system/message/' + id,
    method: 'get'
  })
}

// 新增系统消息
export function addMessage(data) {
  return request({
    url: '/system/message',
    method: 'post',
    data: data
  })
}

// 修改系统消息
export function updateMessage(data) {
  return request({
    url: '/system/message',
    method: 'put',
    data: data
  })
}

// 删除系统消息
export function delMessage(id) {
  return request({
    url: '/system/message/' + id,
    method: 'delete'
  })
}

// 导出系统消息
export function exportMessage(query) {
  return request({
    url: '/system/message/export',
    method: 'get',
    params: query
  })
}

// 消息已读
export function readMessage(id) {
  return request({
    url: '/system/message/read',
    method: 'put',
    params: {id}
  })
}
