import request from './request'

// 查询钱包充值列表
export function listWalletRecharge(query) {
  return request({
    url: '/product/walletRecharge/list',
    method: 'get',
    params: query
  })
}

// 查询钱包充值详细
export function getWalletRecharge(id) {
  return request({
    url: '/product/walletRecharge/' + id,
    method: 'get'
  })
}

// 新增钱包充值
export function addWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge',
    method: 'post',
    data: data
  })
}

// 支付钱包充值
export function payWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge/pay',
    method: 'post',
    data: data
  })
}

// 支付钱包充值
export function payAuditWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge/payAudit',
    method: 'post',
    data: data
  })
}

// 获取第三方支付
export function getWalletRechargePayInfo(id) {
  return request({
    url: '/product/walletRecharge/payInfo/' + id,
    method: 'get'
  })
}

// 获取
export function checkPayStatusWalletRecharge(id) {
  return request({
    url: '/product/walletRecharge/checkPay/' + id,
    method: 'get'
  })
}

// 申请退款
export function applyRefundWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge/applyRefund',
    method: 'post',
    data: data
  })
}
// 审核退款
export function auditRefundWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge/auditRefund',
    method: 'post',
    data: data
  })
}

// 修改钱包充值
export function updateWalletRecharge(data) {
  return request({
    url: '/product/walletRecharge',
    method: 'put',
    data: data
  })
}

// 取消钱包充值订单
export function cancelWalletRecharge(id) {
  return request({
    url: '/product/walletRecharge/cancel/' + id,
    method: 'delete'
  })
}

// 导出钱包充值
export function exportWalletRecharge(query) {
  return request({
    url: '/product/walletRecharge/export',
    method: 'get',
    params: query
  })
}
